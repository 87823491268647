<template>
    <div>
        <div class="card flex justify-between items-center">
            <h2>{{ $t('statistics.statistics') }}</h2>
            <DateRangePicker :subtractedMonths="6" @change="changeDateRange" />
        </div>
        <NewClientsOverTime :dateRange="dateRange" />
        <LoggedHoursPerDay :dateRange="dateRange" />
        <ServicesTypes :dateRange="dateRange" />
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        DateRangePicker:    () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
        NewClientsOverTime: () => import(/* webpackChunkName: "Statistics/NewClientsOverTime" */ './components/NewClientsOverTime'),
        LoggedHoursPerDay:  () => import(/* webpackChunkName: "Statistics/LoggedHoursPerDay" */ './components/LoggedHoursPerDay'),
        ServicesTypes:      () => import(/* webpackChunkName: "Statistics/ServicesTypes" */ './components/ServicesTypes'),
    },

    data() {
        return {
            dateRange: {},
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('statistics.statistics'));
        this.$store.commit('setActiveMenuItem', '9');
        this.changeDateRange();
    },

    methods: {
        changeDateRange() {
            const query = queryString.parse(location.search, { sort: false });
            this.dateRange = {
                from: query.from ? query.from : this.$dayjs().subtract(6, 'months').startOf('month').unix(),
                to: query.to ? query.to : this.$dayjs().endOf('month').unix(),
            };
        },
    },
};
</script>
